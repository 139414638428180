import React, { useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

function BlogPostModal({ post, onClose }) {
  const modalRef = useRef();

  // Remove the metadata section (content between --- markers)
  const removeMetadata = (content) => {
    const parts = content.split("---");
    return parts.length >= 3 ? parts.slice(2).join("---").trim() : content;
  };

  const contentWithoutMetadata = removeMetadata(post.content);

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div
        ref={modalRef}
        className="blog-post-modal bg-white rounded-lg w-full max-w-4xl max-h-[90vh] overflow-y-auto relative"
      >
        <button
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <div className="p-6 text-black">
          <h2 className="text-3xl font-bold mb-4">{post.title}</h2>
          <p className="text-sm text-gray-600 mb-4">{post.date}</p>
          <div className="prose max-w-none text-black">
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {contentWithoutMetadata}
            </ReactMarkdown>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogPostModal;
