import React from "react";

function Contact() {
  const bgImage = process.env.PUBLIC_URL + "/assets/bg.jpg";
  const triaImage = process.env.PUBLIC_URL + "/assets/tria.jpg";

  return (
    <div>
      <div
        className="hero-content"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <h2 className="text-4xl font-bold mt-8">
          <span className="blinking-text-1">Let's</span>{" "}
          <span className="blinking-text-2">Build</span>{" "}
          <span className="gradient-text">Together</span>
        </h2>
        <div className="mt-6">
          <button className="btn-primary mx-2">
            <a href="mailto:info@lt.plus">Send Email</a>
          </button>
        </div>
      </div>
      <div
        className="content"
        style={{
          margin: "2rem auto",
          padding: "1rem",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p className="text-lg">
          Reach out for more information & to see how we can work together.
        </p>
      </div>
      <div
        className="full-width-image"
        style={{
          backgroundImage: `url(${triaImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "400px",
          position: "relative",
          marginTop: "2rem",
          padding: "2rem 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="overlay"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        ></div>
        <div
          className="content"
          style={{
            position: "relative",
            zIndex: 1,
            color: "white",
            width: "50%",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            backdropFilter: "blur(2px)",
            padding: "2rem",
          }}
        >
          <h2 className="text-3xl font-semibold">
            Get in <span className="gradient-text">touch</span>
          </h2>
          <p className="mt-4">
            Office: Max-Högger-Strasse 6, 8048 Zürich, Switzerland
            <br />
            Email:{" "}
            <a href="mailto:info@lt.plus" className="email-link">
              info@lt.plus
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Contact;
