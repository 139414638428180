import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import BlogPost from "../components/BlogPost";
import BlogPostModal from "../components/BlogPostModal";

function Blog() {
  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const bgImage = process.env.PUBLIC_URL + "/assets/bg.jpg";
  const triaBgImage = process.env.PUBLIC_URL + "/assets/tria-background.jpg";
  const blogReelRef = useRef(null);

  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        // Load the list of blog posts from a static JSON file
        const response = await fetch("/blog-posts.json");
        const postList = await response.json();

        // Fetch the content of each blog post
        const postsWithContent = await Promise.all(
          postList.map(async (post) => {
            const contentResponse = await fetch(`/blog-posts/${post.slug}.md`);
            const content = await contentResponse.text();
            return { ...post, content };
          })
        );

        setPosts(postsWithContent);

        // Log only the titles of the blog posts
        console.log(
          "Blog post titles:",
          postsWithContent.map((post) => post.title)
        );
      } catch (error) {
        console.error("Error fetching blog posts:", error);
        // If you want to handle errors, you can add error handling logic here
      }
    };

    fetchBlogPosts();
  }, []);

  const handlePostClick = (post) => {
    setSelectedPost(post);
  };

  const handleCloseModal = () => {
    setSelectedPost(null);
  };

  const updateArrowVisibility = () => {
    if (blogReelRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = blogReelRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft + clientWidth < scrollWidth);
    }
  };

  useEffect(() => {
    updateArrowVisibility();
    window.addEventListener("resize", updateArrowVisibility);
    return () => window.removeEventListener("resize", updateArrowVisibility);
  }, [posts]);

  const scrollBlogReel = (direction) => {
    if (blogReelRef.current) {
      const scrollAmount = blogReelRef.current.offsetWidth * 0.8;
      blogReelRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
      setTimeout(updateArrowVisibility, 500); // Update after scroll animation
    }
  };

  return (
    <div className="min-h-screen">
      <div
        className="hero-content"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "40vh",
        }}
      >
        <div className="text-center text-white">
          <h2 className="text-4xl font-bold mt-8">
            <span className="gradient-text">Insights</span>{" "}
            <span className="blinking-text-1">and</span>{" "}
            <span className="gradient-text">Updates</span>{" "}
          </h2>
          <div className="mt-6">
            <button className="btn-primary mx-2">
              <Link to="/contact">Get Started</Link>
            </button>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundImage: `url(${triaBgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container mx-auto px-4 py-8">
          <h3 className="text-2xl font-bold text-white text-center mb-8">
            Latest Blog Posts & News
          </h3>
          <div className="blog-reel-container relative">
            {showLeftArrow && (
              <button
                className="scroll-arrow left absolute left-0 top-1/2 transform -translate-y-1/2 z-10"
                onClick={() => scrollBlogReel("left")}
                aria-label="Scroll left"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  width="24"
                  height="24"
                  className="text-white"
                >
                  <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
                </svg>
              </button>
            )}
            <div
              className="blog-reel flex overflow-x-auto space-x-4 pb-4"
              ref={blogReelRef}
              onScroll={updateArrowVisibility}
            >
              {posts.map((post) => (
                <div key={post.slug} className="flex-shrink-0 w-64">
                  <BlogPost post={post} onClick={() => handlePostClick(post)} />
                </div>
              ))}
            </div>
            {showRightArrow && (
              <button
                className="scroll-arrow right absolute right-0 top-1/2 transform -translate-y-1/2 z-10"
                onClick={() => scrollBlogReel("right")}
                aria-label="Scroll right"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  width="24"
                  height="24"
                  className="text-white"
                >
                  <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
                </svg>
              </button>
            )}
          </div>
        </div>
      </div>
      {selectedPost && (
        <BlogPostModal post={selectedPost} onClose={handleCloseModal} />
      )}
    </div>
  );
}

export default Blog;
