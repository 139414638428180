import React, { useRef, useState, useEffect } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from "three";

function Model({ modelPath, index, isInteracting, onInteraction }) {
  const [model, setModel] = useState(null);
  const [error, setError] = useState(null);
  const modelRef = useRef();
  const { camera } = useThree();
  const rotationRef = useRef({ angle: 0, speed: 0.05, completed: false });
  const zoomAnimationRef = useRef({ progress: 0, completed: false });
  const autoRotateRef = useRef({
    angle: 0,
    direction: 1,
    phase: "forward",
    completed: false,
    extraRotation: 0,
  });

  useEffect(() => {
    if (!modelPath) {
      console.error("Model path is undefined");
      return;
    }

    const loader = new GLTFLoader();
    loader.load(
      modelPath,
      (gltf) => {
        const scene = gltf.scene;

        if (index === 0) {
          scene.rotation.x = Math.PI / 2;
          scene.position.x = 0.5;
        } else if (index === 1) {
          scene.rotation.y = Math.PI / 2;
        }

        if (index === 0 || index === 2) {
          scene.traverse((child) => {
            if (child.isMesh) {
              const edges = new THREE.EdgesGeometry(child.geometry);
              const line = new THREE.LineSegments(
                edges,
                new THREE.LineBasicMaterial({ color: 0xffffff, linewidth: 1 })
              );
              child.add(line);
            }
          });
        }

        setModel(scene);

        const box = new THREE.Box3().setFromObject(scene);
        const center = box.getCenter(new THREE.Vector3());
        scene.position.sub(center);

        if (index === 0) {
          camera.position.set(-4, -10, 20);
        } else if (index === 1) {
          camera.position.set(-4, 5, 25);
        } else if (index === 2) {
          camera.position.set(0, 0, 1.5);
        }

        camera.lookAt(0, 0, 0);
        camera.updateProjectionMatrix();
      },
      undefined,
      (err) => {
        console.error("Error loading GLB file:", err);
        setError(err);
      }
    );
  }, [camera, modelPath, index]);

  useFrame((state) => {
    if (modelRef.current && !isInteracting) {
      if (index === 0) {
        // Zoom animation for left model
        if (!zoomAnimationRef.current.completed) {
          zoomAnimationRef.current.progress += 0.01;
          if (zoomAnimationRef.current.progress >= 1) {
            zoomAnimationRef.current.completed = true;
          }
          const zoomProgress = Math.min(zoomAnimationRef.current.progress, 1);
          camera.position.z = THREE.MathUtils.lerp(20, 10, zoomProgress);
          camera.updateProjectionMatrix();
        } else if (!autoRotateRef.current.completed) {
          // Auto-rotation for left model
          autoRotateRef.current.angle +=
            0.001 * autoRotateRef.current.direction;
          modelRef.current.rotation.z = autoRotateRef.current.angle;

          if (
            autoRotateRef.current.phase === "forward" &&
            autoRotateRef.current.angle >= Math.PI / 7.2
          ) {
            autoRotateRef.current.direction = -1;
            autoRotateRef.current.phase = "backward";
          } else if (
            autoRotateRef.current.phase === "backward" &&
            autoRotateRef.current.angle <= 0
          ) {
            autoRotateRef.current.completed = true;
          }
        }
      } else if (index === 1) {
        // Rotation animation for the middle model
        if (!rotationRef.current.completed) {
          rotationRef.current.angle += rotationRef.current.speed;
          modelRef.current.rotation.y = Math.PI / 2 + rotationRef.current.angle;

          rotationRef.current.speed *= 0.99;

          if (rotationRef.current.speed < 0.0001) {
            rotationRef.current.completed = true;
          }

          if (rotationRef.current.angle >= Math.PI * 2) {
            rotationRef.current.angle = Math.PI * 2;
            rotationRef.current.speed *= 0.9;
          }
        }
      } else if (index === 2) {
        // Auto-rotation for right model
        if (!autoRotateRef.current.completed) {
          autoRotateRef.current.angle +=
            0.001 * autoRotateRef.current.direction;

          if (
            autoRotateRef.current.phase === "forward" &&
            autoRotateRef.current.angle >= Math.PI / 7.2
          ) {
            autoRotateRef.current.direction = -1;
            autoRotateRef.current.phase = "backward";
          } else if (
            autoRotateRef.current.phase === "backward" &&
            autoRotateRef.current.angle <= 0
          ) {
            autoRotateRef.current.phase = "extra";
            autoRotateRef.current.direction = -1;
          } else if (autoRotateRef.current.phase === "extra") {
            autoRotateRef.current.extraRotation += 0.001;
            if (autoRotateRef.current.extraRotation >= Math.PI / 4) {
              // 45 degrees
              autoRotateRef.current.completed = true;
            }
          }

          if (autoRotateRef.current.phase === "extra") {
            modelRef.current.rotation.y = autoRotateRef.current.extraRotation;
          } else {
            modelRef.current.rotation.y = autoRotateRef.current.angle;
          }
        }
      }
    }
  });

  if (error) {
    return (
      <mesh>
        <boxGeometry args={[1, 1, 1]} />
        <meshStandardMaterial color="red" />
      </mesh>
    );
  }

  return model ? (
    <primitive object={model} ref={modelRef} onPointerDown={onInteraction} />
  ) : null;
}

function ThreeViewer({ modelPath, index, isInteracting, onInteraction }) {
  return (
    <Canvas
      camera={{ position: [0, 0, 6], fov: 50 }}
      style={{ width: "100%", height: "100%" }}
    >
      <ambientLight intensity={0.5} />
      <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
      <pointLight position={[-10, -10, -10]} />
      <Model
        modelPath={modelPath}
        index={index}
        isInteracting={isInteracting}
        onInteraction={onInteraction}
      />
      <OrbitControls
        enableZoom={true}
        enablePan={true}
        enableRotate={true}
        minPolarAngle={Math.PI / 4}
        maxPolarAngle={Math.PI / 1.5}
      />
    </Canvas>
  );
}

export default ThreeViewer;
