import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function Projects() {
  const bgImage = process.env.PUBLIC_URL + "/assets/bg.jpg";
  const [currentSlogan, setCurrentSlogan] = useState(0);
  const [isChanging, setIsChanging] = useState(false);
  const [isGradientChanging, setIsGradientChanging] = useState(false);

  const slogans = [
    { start: "Still", highlight: "Under Construction", end: "" },
    { start: "Still", highlight: "Building", end: "Awesome Stuff" },
    { start: "I Like", highlight: "Building", end: "..." },
    { start: "From", highlight: "Buildings to Bytes", end: "..." },
    { start: "Constructing", highlight: "Digital", end: "for tomorrow" },
    { start: "Still", highlight: "Building", end: "the Future" },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setIsChanging(true);

      setTimeout(() => {
        setIsGradientChanging(true);
      }, 800);

      setTimeout(() => {
        setCurrentSlogan((prev) => (prev + 1) % slogans.length);
        setIsChanging(false);
        setIsGradientChanging(false);
      }, 1600);
    }, 5000);

    return () => clearInterval(interval);
  }, [slogans.length]);

  return (
    <div className="min-h-screen">
      <div
        className="hero-content"
        style={{
          backgroundImage: `url(${bgImage})`,
          minHeight: "60vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h2 className="text-4xl font-bold">
          <span
            className={`blinking-text-1 transition-opacity duration-700 ${
              isChanging ? "opacity-0" : "opacity-100"
            }`}
          >
            {slogans[currentSlogan].start}{" "}
          </span>
          <span
            className={`gradient-text transition-opacity duration-700 ${
              isGradientChanging ? "opacity-0" : "opacity-100"
            }`}
          >
            {slogans[currentSlogan].highlight}
          </span>
          <span
            className={`blinking-text-2 transition-opacity duration-700 ${
              isChanging ? "opacity-0" : "opacity-100"
            }`}
          >
            {" "}
            {slogans[currentSlogan].end}
          </span>
        </h2>
        <div className="mt-6">
          <button className="btn-primary mx-2">
            <Link to="/contact">Get Started</Link>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Projects;
