import React, {
  Suspense,
  useState,
  useCallback,
  useRef,
  useEffect,
} from "react";
import { Link } from "react-router-dom";
import ThreeViewer from "../components/ThreeViewer";
import "./Home.css";

function Home() {
  const bgImage = process.env.PUBLIC_URL + "/assets/bg.jpg";
  const models = [
    "/assets/infra_data.glb",
    "/assets/sample.glb",
    "/assets/facade.glb",
  ];
  const [interactingModels, setInteractingModels] = useState([
    false,
    false,
    false,
  ]);
  const viewerRefs = useRef([]);
  const [showGlow, setShowGlow] = useState(false);
  const [breathingComplete, setBreathingComplete] = useState([
    false,
    false,
    false,
  ]);

  const handleInteraction = useCallback((index) => {
    setInteractingModels((prev) => {
      const newState = [...prev];
      newState[index] = true;
      return newState;
    });

    const handleMouseMove = (e) => {
      const viewerRect = viewerRefs.current[index].getBoundingClientRect();
      const distance = Math.sqrt(
        Math.pow(e.clientX - viewerRect.left - viewerRect.width / 2, 2) +
          Math.pow(e.clientY - viewerRect.top - viewerRect.height / 2, 2)
      );

      if (distance > viewerRect.width / 2) {
        setInteractingModels((prev) => {
          const newState = [...prev];
          newState[index] = false;
          return newState;
        });
        document.removeEventListener("mousemove", handleMouseMove);
      }
    };

    document.addEventListener("mousemove", handleMouseMove);
  }, []);

  useEffect(() => {
    const viewers = viewerRefs.current;
    const timer = setTimeout(() => {
      setShowGlow(true);
      viewers.forEach((viewer, index) => {
        if (viewer) {
          viewer.classList.add(`glow-effect-${index + 1}`);
          setTimeout(() => {
            setBreathingComplete((prev) => {
              const newState = [...prev];
              newState[index] = true;
              return newState;
            });
          }, 4000); // 4 seconds for the breathing animation
        }
      });
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <div
        className="hero-content"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <h2 className="text-4xl font-bold mt-8">
          <span className="gradient-text">Digital</span>{" "}
          <span className="blinking-text-1">Planning.</span>{" "}
          <span className="gradient-text">Sustainable</span>{" "}
          <span className="blinking-text-2">Construction.</span>
        </h2>
        <div className="mt-6">
          <button className="btn-primary mx-2">
            <Link to="/contact">Get Started</Link>
          </button>
        </div>
      </div>
      <div
        className="content"
        style={{
          margin: "2rem auto",
          padding: "1rem",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p className="text-lg">
          Environmental impact optimization & digital construction for the built
          environment.
        </p>
      </div>
      <div
        className="full-width-image"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "400px",
          position: "relative",
          marginTop: "2rem",
          padding: "2rem 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            zIndex: 1,
            width: "100%",
            maxWidth: "1200px",
          }}
        >
          {models.map((modelPath, index) => (
            <div
              key={index}
              ref={(el) => (viewerRefs.current[index] = el)}
              className={`three-viewer-container ${
                showGlow ? `glow-effect-${index + 1}` : ""
              } ${breathingComplete[index] ? "reduced-glow" : ""} ${
                interactingModels[index] ? "grabbed" : ""
              }`}
              style={{
                width: index === 1 ? "280px" : "220px",
                height: index === 1 ? "280px" : "220px",
                borderRadius: "8px",
                overflow: "hidden",
                margin: "0 3.5rem",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                backgroundColor: "transparent",
              }}
            >
              <Suspense
                fallback={
                  <div className="loading-text">Loading 3D Model...</div>
                }
              >
                <ThreeViewer
                  modelPath={modelPath}
                  index={index}
                  isInteracting={interactingModels[index]}
                  onInteraction={() => handleInteraction(index)}
                />
              </Suspense>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Home;
