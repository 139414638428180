import React, { useState, useEffect, useRef } from "react";

function NekoAnimation({ barriers = [] }) {
  const [nekoActive, setNekoActive] = useState(false);
  const nekoRef = useRef(null);
  const animatedNekoRef = useRef(null);

  useEffect(() => {
    if (nekoActive && !animatedNekoRef.current) {
      function neko() {
        var nekoEl = document.createElement("div");
        animatedNekoRef.current = nekoEl;
        var nekoPosX = window.innerWidth - 32;
        var nekoPosY = window.innerHeight - 32;
        var mousePosX = 0;
        var mousePosY = 0;
        var frameCount = 0;
        var idleTime = 0;
        var idleAnimation = null;
        var idleAnimationFrame = 0;
        var direction;
        var nekoSpeed = 10;
        var grabbed = false;
        var succeeded = false;
        var scratching = false;
        var scratchTime = 0;
        var lastBarrier = null;
        var hasScratched = false;
        var hasScratchedScreen = false;
        var lastMoveDirection = null;

        var spriteSets = {
          idle: [[-3, -3]],
          alert: [[-7, -3]],
          scratchSelf: [
            [-5, 0],
            [-6, 0],
            [-7, 0],
          ],
          scratchWallN: [
            [0, 0],
            [0, -1],
          ],
          scratchWallS: [
            [-7, -1],
            [-6, -2],
          ],
          scratchWallE: [
            [-2, -2],
            [-2, -3],
          ],
          scratchWallW: [
            [-4, 0],
            [-4, -1],
          ],
          tired: [[-3, -2]],
          sleeping: [
            [-2, 0],
            [-2, -1],
          ],
          N: [
            [-1, -2],
            [-1, -3],
          ],
          NE: [
            [0, -2],
            [0, -3],
          ],
          E: [
            [-3, 0],
            [-3, -1],
          ],
          SE: [
            [-5, -1],
            [-5, -2],
          ],
          S: [
            [-6, -3],
            [-7, -2],
          ],
          SW: [
            [-5, -3],
            [-6, -1],
          ],
          W: [
            [-4, -2],
            [-4, -3],
          ],
          NW: [
            [-1, 0],
            [-1, -1],
          ],
          grab: [[-4, -1]],
          success: [
            [-5, -2],
            [-5, -3],
          ],
        };

        function init() {
          nekoEl.id = "oneko";
          nekoEl.ariaHidden = true;
          nekoEl.style.width = "32px";
          nekoEl.style.height = "32px";
          nekoEl.style.position = "fixed";
          nekoEl.style.pointerEvents = "none";
          nekoEl.style.backgroundImage = "url('/assets/oneko.gif')";
          nekoEl.style.imageRendering = "pixelated";
          nekoEl.style.left = nekoPosX - 16 + "px";
          nekoEl.style.top = nekoPosY - 16 + "px";
          nekoEl.style.zIndex = "10000";

          document.body.appendChild(nekoEl);
          document.addEventListener("mousemove", mousePos);
          document.addEventListener("mousedown", grab);
          document.addEventListener("mouseup", release);
          window.onekoInterval = setInterval(frame, 100);
        }

        function mousePos(event) {
          mousePosX = event.clientX;
          mousePosY = event.clientY;
        }

        function grab() {
          if (
            Math.abs(mousePosX - nekoPosX) < 32 &&
            Math.abs(mousePosY - nekoPosY) < 32
          ) {
            grabbed = true;
            setSprite("grab", 0);
          }
        }

        function release() {
          if (grabbed) {
            grabbed = false;
            succeeded = true;
            setSprite("success", 0);
            setTimeout(() => {
              succeeded = false;
            }, 2000);
          }
        }

        function setSprite(name, frame) {
          var sprite = spriteSets[name][frame % spriteSets[name].length];
          nekoEl.style.backgroundPosition =
            sprite[0] * 32 + "px " + sprite[1] * 32 + "px";
        }

        function resetIdleAnimation() {
          idleAnimation = null;
          idleAnimationFrame = 0;
        }

        function idle() {
          idleTime += 1;
          if (
            idleTime > 10 &&
            Math.floor(Math.random() * 200) === 0 &&
            idleAnimation == null
          ) {
            var availableIdleAnimations = ["sleeping", "scratchSelf"];
            if (nekoPosX < 32) availableIdleAnimations.push("scratchWallW");
            if (nekoPosY < 32) availableIdleAnimations.push("scratchWallN");
            if (nekoPosX > window.innerWidth - 32)
              availableIdleAnimations.push("scratchWallE");
            if (nekoPosY > window.innerHeight - 32)
              availableIdleAnimations.push("scratchWallS");
            idleAnimation =
              availableIdleAnimations[
                Math.floor(Math.random() * availableIdleAnimations.length)
              ];
          }

          switch (idleAnimation) {
            case "sleeping":
              if (idleAnimationFrame < 8) {
                setSprite("tired", 0);
                break;
              }
              setSprite("sleeping", Math.floor(idleAnimationFrame / 4));
              if (idleAnimationFrame > 192) resetIdleAnimation();
              break;
            case "scratchWallN":
            case "scratchWallS":
            case "scratchWallE":
            case "scratchWallW":
            case "scratchSelf":
              setSprite(idleAnimation, idleAnimationFrame);
              if (idleAnimationFrame > 9) resetIdleAnimation();
              break;
            default:
              setSprite("idle", 0);
              return;
          }
          idleAnimationFrame += 1;
        }

        function checkBarrierCollision(x, y) {
          if (!barriers || barriers.length === 0) return false;

          for (let barrier of barriers) {
            if (
              x > barrier.left - 16 &&
              x < barrier.right + 16 &&
              y > barrier.top - 16 &&
              y < barrier.bottom + 16
            ) {
              return barrier;
            }
          }
          return false;
        }

        function moveAlongBarrier(barrier, newX, newY, targetX, targetY) {
          const directions = [
            { dx: 0, dy: -1 }, // Up
            { dx: 0, dy: 1 }, // Down
            { dx: -1, dy: 0 }, // Left
            { dx: 1, dy: 0 }, // Right
          ];

          // If we have a last move direction, prioritize it
          if (lastMoveDirection) {
            directions.sort((a, b) => {
              if (
                a.dx === lastMoveDirection.dx &&
                a.dy === lastMoveDirection.dy
              )
                return -1;
              if (
                b.dx === lastMoveDirection.dx &&
                b.dy === lastMoveDirection.dy
              )
                return 1;
              return 0;
            });
          } else {
            // Sort directions based on which one gets us closer to the target
            directions.sort((a, b) => {
              const distA = Math.hypot(
                targetX - (newX + a.dx * nekoSpeed),
                targetY - (newY + a.dy * nekoSpeed)
              );
              const distB = Math.hypot(
                targetX - (newX + b.dx * nekoSpeed),
                targetY - (newY + b.dy * nekoSpeed)
              );
              return distA - distB;
            });
          }

          for (let dir of directions) {
            const testX = newX + dir.dx * nekoSpeed;
            const testY = newY + dir.dy * nekoSpeed;

            if (!checkBarrierCollision(testX, testY)) {
              lastMoveDirection = dir;
              return { x: testX, y: testY };
            }
          }

          // If all directions are blocked, stay in place and reset lastMoveDirection
          lastMoveDirection = null;
          return { x: newX, y: newY };
        }

        function scratch(direction) {
          scratching = true;
          scratchTime = 10; // Scratch for 10 frames
          hasScratched = true;

          let scratchDirection;
          if (direction.includes("N")) scratchDirection = "scratchWallN";
          else if (direction.includes("S")) scratchDirection = "scratchWallS";
          else if (direction.includes("E")) scratchDirection = "scratchWallE";
          else if (direction.includes("W")) scratchDirection = "scratchWallW";
          else scratchDirection = "scratchSelf";

          setSprite(scratchDirection, frameCount);
        }

        function frame() {
          frameCount += 1;

          if (grabbed) {
            nekoPosX = mousePosX;
            nekoPosY = mousePosY;
          } else if (succeeded) {
            setSprite("success", frameCount);
          } else if (scratching) {
            scratchTime -= 1;
            if (scratchTime <= 0) {
              scratching = false;
            }
          } else {
            var diffX = nekoPosX - mousePosX;
            var diffY = nekoPosY - mousePosY;
            var distance = Math.sqrt(Math.pow(diffX, 2) + Math.pow(diffY, 2));

            if (distance < nekoSpeed || distance < 48) {
              idle();
              return;
            }

            idleAnimation = null;
            idleAnimationFrame = 0;

            if (idleTime > 1) {
              setSprite("alert", 0);
              idleTime = Math.min(idleTime, 7);
              idleTime -= 1;
              return;
            }

            direction = "";
            if (diffY / distance > 0.5) direction = "N";
            else if (diffY / distance < -0.5) direction = "S";
            if (diffX / distance > 0.5) direction += "W";
            else if (diffX / distance < -0.5) direction += "E";
            setSprite(direction, frameCount);

            var newNekoPosX = nekoPosX;
            var newNekoPosY = nekoPosY;

            if (distance > nekoSpeed) {
              newNekoPosX -= (diffX / distance) * nekoSpeed;
              newNekoPosY -= (diffY / distance) * nekoSpeed;
            } else {
              newNekoPosX = mousePosX;
              newNekoPosY = mousePosY;
            }

            const collision = checkBarrierCollision(newNekoPosX, newNekoPosY);
            if (collision) {
              if (collision !== lastBarrier) {
                lastBarrier = collision;
                hasScratched = false;
                lastMoveDirection = null; // Reset lastMoveDirection when encountering a new barrier
              }
              if (!hasScratched && !hasScratchedScreen && Math.random() < 0.1) {
                scratch(direction);
                hasScratchedScreen = true;
              } else {
                const newPosition = moveAlongBarrier(
                  collision,
                  nekoPosX,
                  nekoPosY,
                  mousePosX,
                  mousePosY
                );
                newNekoPosX = newPosition.x;
                newNekoPosY = newPosition.y;
              }
            } else {
              lastBarrier = null;
              hasScratched = false;
              lastMoveDirection = null; // Reset lastMoveDirection when not colliding
            }

            nekoPosX = newNekoPosX;
            nekoPosY = newNekoPosY;
          }

          // Check if the cat has moved to a new screen
          if (
            nekoPosX < 0 ||
            nekoPosX > document.documentElement.clientWidth ||
            nekoPosY < 0 ||
            nekoPosY > document.documentElement.clientHeight
          ) {
            hasScratchedScreen = false;
          }

          nekoPosX = Math.min(
            Math.max(16, nekoPosX),
            document.documentElement.clientWidth - 16
          );
          nekoPosY = Math.min(
            Math.max(16, nekoPosY),
            document.documentElement.clientHeight - 16
          );

          nekoEl.style.left = nekoPosX - 16 + "px";
          nekoEl.style.top = nekoPosY - 16 + "px";
        }

        init();
      }

      neko();
      if (nekoRef.current) {
        nekoRef.current.style.display = "none";
      }
    }
  }, [nekoActive, barriers]);

  const handleClick = () => {
    if (!nekoActive) {
      setNekoActive(true);
    }
  };

  return (
    !nekoActive && (
      <div
        ref={nekoRef}
        style={{
          position: "fixed",
          right: "16px",
          bottom: "16px",
          width: "32px",
          height: "32px",
          backgroundImage: "url('/assets/oneko.gif')",
          backgroundPosition: "-3px -3px",
          imageRendering: "pixelated",
          cursor: "pointer",
          zIndex: 9999,
        }}
        onClick={handleClick}
      />
    )
  );
}

export default NekoAnimation;
