import React from "react";
import { Link } from "react-router-dom";

function About() {
  const bgImage = process.env.PUBLIC_URL + "/assets/bg.jpg";
  const triaImage = process.env.PUBLIC_URL + "/assets/tria.jpg";
  const image1 = process.env.PUBLIC_URL + "/assets/lt.jpg";
  const image2 = process.env.PUBLIC_URL + "/assets/talk.jpg";

  return (
    <div>
      <div
        className="hero-content"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <h2 className="text-4xl font-bold mt-8">
          <span className="gradient-text">Vision</span>{" "}
          <span className="blinking-text-1">for a</span>{" "}
          <span className="gradient-text">Sustainable</span>{" "}
          <span className="blinking-text-2">Future</span>
        </h2>
        <div className="mt-6">
          <button className="btn-primary mx-2">
            <Link to="/contact">Get Started</Link>
          </button>
        </div>
      </div>
      <div
        className="content"
        style={{
          margin: "2rem auto",
          padding: "1rem",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p className="text-lg">LT+ is more than just a construction company.</p>
      </div>
      <div
        className="full-width-image"
        style={{
          backgroundImage: `url(${triaImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "400px",
          position: "relative",
          marginTop: "2rem",
          padding: "2rem 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="overlay"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        ></div>
        <div
          className="content"
          style={{
            position: "relative",
            zIndex: 1,
            color: "white",
            width: "100%",
            maxWidth: "1200px",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            backdropFilter: "blur(2px)",
            padding: "2rem",
          }}
        >
          <div className="flex justify-center items-center">
            <div className="m-4" style={{ width: "20%" }}>
              <img
                src={image1}
                alt="Louis Trümpler"
                style={{
                  width: "200px",
                  borderRadius: "8px",
                  objectFit: "cover",
                  objectPosition: "center 20%",
                }}
              />
            </div>
            <div className="m-4" style={{ width: "60%" }}>
              <p className="mt-4">
                Founded in 2023 by Louis Trümpler, a timber specialist on a
                mission to reduce the environmental impact of the construction
                industry through sustainable design and innovative technology.
              </p>
              <p className="mt-2">
                With extensive experience in construction, I am combining my
                expertise in digital technologies and sustainable construction
                to offer a unique blend of openBIM, Open Source, Software / Web
                development and environmentally optimized construction.
              </p>
            </div>
            <div className="m-4" style={{ width: "20%" }}>
              <img
                src={image2}
                alt="LT+ at work"
                style={{
                  borderRadius: "8px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
