import React from "react";
import { Link } from "react-router-dom";

function Solutions() {
  const bgImage = process.env.PUBLIC_URL + "/assets/bg.jpg";
  const triaImage = process.env.PUBLIC_URL + "/assets/tria.jpg";

  return (
    <div>
      <div
        className="hero-content"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <h2 className="text-4xl font-bold mt-8">
          <span className="gradient-text">Innovation</span>{" "}
          <span className="blinking-text-1">for</span>{" "}
          <span className="gradient-text">Sustainable</span>{" "}
          <span className="blinking-text-2">Construction</span>
        </h2>
        <div className="mt-6">
          <button className="btn-primary mx-2">
            <Link to="/contact">Get Started</Link>
          </button>
        </div>
      </div>
      <div
        className="content"
        style={{
          margin: "2rem auto",
          padding: "1rem",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p className="text-lg">
          Offering a range of solutions to optimize environmental impact and
          construction planning.
        </p>
      </div>
      <div
        className="full-width-image"
        style={{
          backgroundImage: `url(${triaImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "400px",
          position: "relative",
          marginTop: "2rem",
          padding: "2rem 1rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="overlay"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        ></div>
        <div
          className="content"
          style={{
            position: "relative",
            zIndex: 1,
            color: "white",
            width: "100%",
            maxWidth: "1200px",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            backdropFilter: "blur(2px)",
            padding: "2rem",
          }}
        >
          <div className="flex flex-wrap justify-center">
            <div className="card m-4" style={{ width: "30%" }}>
              <h3 className="text-xl font-semibold gradient-text">
                Environmentally optimized projects
              </h3>
              <p style={{ marginTop: "1rem" }}>
                Helping owners and project teams define specific goals, avoid
                negative environmental impacts, and transparently document
                results throughout the construction process.
              </p>
            </div>
            <div className="card m-4" style={{ width: "30%" }}>
              <h3 className="text-xl font-semibold gradient-text">
                Digital construction and planning processes
              </h3>
              <p style={{ marginTop: "1rem" }}>
                Navigating the digital landscape in construction can be
                daunting.
                <br />
                LT+ offers expertise and Know-How in Building Information
                Modeling based on extensive project work.
              </p>
            </div>
            <div className="card m-4" style={{ width: "30%" }}>
              <h3 className="text-xl font-semibold gradient-text">
                Modern timber construction
              </h3>
              <p style={{ marginTop: " 1rem" }}>
                Automated workflows and advanced interface solutions using tools
                like Revit, Blender and Cadwork guarantee precision and
                efficiency for structurally sound and beautiful buildings.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Solutions;
