import React from "react";

function BlogPost({ post, onClick }) {
  return (
    <div
      className="blog-post-card rounded-lg overflow-hidden cursor-pointer transform transition-transform duration-300 hover:scale-105"
      onClick={onClick}
    >
      <img
        src={post.image || "https://via.placeholder.com/300x100"}
        alt={post.title}
        className="w-full h-24 object-cover"
      />
      <div className="p-3 text-white">
        <h3 className="text-base font-semibold mb-1 truncate">{post.title}</h3>
        <p className="text-xs opacity-80 mb-1">{post.date}</p>
        <p className="text-xs opacity-90 line-clamp-2">{post.excerpt}</p>
      </div>
    </div>
  );
}

export default BlogPost;
