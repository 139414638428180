import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  NavLink,
} from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import About from "./pages/About";
import Solutions from "./pages/Solutions";
import Contact from "./pages/Contact";
import Blog from "./pages/Blog";
import Projects from "./pages/Projects";
import NekoAnimation from "./components/NekoAnimation";

function App() {
  const [barriers, setBarriers] = useState([]);

  useEffect(() => {
    const updateBarriers = () => {
      const barrierElements = document.querySelectorAll(
        ".three-viewer-container, .card, .btn-primary, .btn-secondary"
      );
      const newBarriers = Array.from(barrierElements).map((el) => {
        const rect = el.getBoundingClientRect();
        return {
          left: rect.left,
          right: rect.right,
          top: rect.top,
          bottom: rect.bottom,
        };
      });
      setBarriers(newBarriers);
    };

    updateBarriers();
    window.addEventListener("resize", updateBarriers);
    // Add a MutationObserver to detect DOM changes
    const observer = new MutationObserver(updateBarriers);
    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      window.removeEventListener("resize", updateBarriers);
      observer.disconnect();
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <nav className="flex justify-between items-center p-4">
            <div className="flex items-center">
              <NavLink to="/" end>
                <img
                  src="/LT+_whiteOnBlack.png"
                  alt="LTplus Logo"
                  className="h-8 logo"
                />
              </NavLink>
            </div>
            <div>
              <NavLink
                to="/solutions"
                className={({ isActive }) =>
                  `email-link mx-2 ${isActive ? "font-bold" : ""}`
                }
              >
                Solutions
              </NavLink>
              <NavLink
                to="/about"
                className={({ isActive }) =>
                  `email-link mx-2 ${isActive ? "font-bold" : ""}`
                }
              >
                About
              </NavLink>
              <NavLink
                to="/blog"
                className={({ isActive }) =>
                  `email-link mx-2 ${isActive ? "font-bold" : ""}`
                }
              >
                Blog
              </NavLink>
              <NavLink
                to="/projects"
                className={({ isActive }) =>
                  `email-link mx-2 ${isActive ? "font-bold" : ""}`
                }
              >
                Projects
              </NavLink>
              <NavLink
                to="/contact"
                className={({ isActive }) =>
                  `email-link mx-2 ${isActive ? "font-bold" : ""}`
                }
              >
                Contact
              </NavLink>
            </div>
          </nav>
        </header>

        <main className="App-main">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/solutions" element={<Solutions />} />
            <Route path="/about" element={<About />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>

        <footer className="App-footer mt-8">
          <p>Enabling digital planning & sustainable construction: LT+</p>
          <p>
            Get in touch at{" "}
            <a href="mailto:info@lt.plus" className="email-link">
              info@lt.plus
            </a>
          </p>
        </footer>

        <NekoAnimation barriers={barriers} />
      </div>
    </Router>
  );
}

export default App;
